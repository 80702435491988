#transition-container {
    position: absolute;
    z-index: 1000;
    width: 200vw;
    bottom: -100%;
    transform: rotate(20deg);
    left: -40%;
    pointer-events: none;
}

#transition-wave-svg {
    transform: translateY(20px);
}

#transition-wave-svg-bottom {
    transform: rotate(180deg) translateY(20px);
}

.hideTopTransition {
    animation: hideTopTransition ease-in .7s;
    animation-fill-mode: forwards;
}

@keyframes hideTopTransition {
    0% {
        bottom: -120%;
        left: -40%;
    }

    100% {
        bottom: 100%;
        left: 40%;
    }
}

.showTransition {
    animation: showTransition ease-in .7s;
    animation-fill-mode: forwards;
}

@keyframes showTransition {
    0% {
        bottom: -450%;
        left: -60%;
    }

    100% {
        bottom: -120%;
        left: -40%;
    }
}

#transition-push {
    height: 200vh;
    background: #FF923E;
}

/**---------------------------------------------------------------Responsive-----------------------------------------------------*/
@media (max-aspect-ratio: 12/10) {
    #transition-container {
        transform: translate(0, -20%) scale(1.2) rotate(10deg);
    }
}